import React, { FC, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
} from "reactstrap";
import { classes, style } from "typestyle";
import caret from "../img/caret.svg";
import { Colour, Structure } from "../theme";
import { IAccount } from "../type";
const background = style({
  backgroundColor: Colour.greyDark,
  borderRadius: Structure.borderRadius + " !important",
  margin: 0,
  padding: 0,
  border: "none",
});
const toggleButton = style({
  borderRadius: Structure.borderRadius + " !important",
  padding: 0,
  $nest: {
    "&:active,&:hover,&:focus": {
      boxShadow: "none !important",
    },
  },
});
const active = style({
  backgroundColor: Colour.greyLight,
});

const btnHeight = 42;
const btnContent = style({
  height: btnHeight,
});
const btnWord = style({});
const caretIcon = style({
  transition: "all 0.3s ease",
});
const rotated = style({
  transform: "rotate(180deg)",
});
const maxHeight = style({
  maxHeight: "80vh",
  overflow: "auto",
});
const btnCaret = ({ colour }) =>
  style({
    width: btnHeight,
    height: btnHeight,
    float: "right",
    backgroundColor: colour,
    color: Colour.white,
    borderBottomRightRadius: Structure.borderRadius,
    borderTopRightRadius: Structure.borderRadius,
  });
const dropdownItem = ({ colour }) =>
  style({
    color: Colour.white,
    overflow: "hidden",
    $nest: {
      "&:hover": {
        backgroundColor: colour,
        color: Colour.white,
      },
    },
  });

type Props = {
  list: IAccount[]; // | IPersona[];
  sublist?: IAccount[]; // | IPersona[];
  filters: any[];
  selectedValue: string | null;
  all?: string;
  onValue: (value: any) => any;
  onFilter: (filter: any) => any;
  selectedFilterName: string | null;
  colour: string;
  hideValueSelector?: boolean;
};
const Dropper: FC<Props> = ({
  selectedValue,
  selectedFilterName,
  list = [],
  sublist = [],
  all,
  onValue,
  colour,
  filters,
  onFilter,
  hideValueSelector = false,
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getChosen = () => {
    switch (true) {
      case !!selectedValue:
        return (
          <span>
            {list
              .concat(sublist)
              .find((account) => account.key === selectedValue)?.name || ""}
          </span>
        );
      case !!selectedFilterName:
        return (
          <span>
            {selectedFilterName} {all}
          </span>
        );
      case !!list.length:
        return <span>{list[0].name}</span>;
      default:
        return <Spinner />;
    }
  };

  return (
    <Row>
      <Col xs={12} sm={6} xl={4}>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            className={classes(
              background,
              toggleButton,
              "w-100 text-uppercase"
            )}
          >
            <div className={btnContent}>
              <div className={classes(btnWord, "d-inline-block  h-100")}>
                <div
                  className={classes(
                    "d-flex justify-content-center align-items-center h-100"
                  )}
                >
                  {getChosen()}
                </div>
              </div>
              <div className={classes(btnCaret({ colour }), "d-inline-block")}>
                <div
                  className={classes(
                    "d-flex justify-content-center align-items-center h-100"
                  )}
                >
                  <img
                    src={caret}
                    alt="VV"
                    className={classes(caretIcon, dropdownOpen && rotated)}
                  />
                </div>
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu className={classes(background, maxHeight, "w-100")}>
            {filters.map((filter) => (
              <DropdownItem
                key={filter.name}
                onClick={() => onFilter(filter.name)}
                className={classes(
                  dropdownItem({ colour }),
                  "mt-2",
                  selectedFilterName === filter.name && active
                )}
              >
                <span className={classes("text-uppercase")}>
                  {filter.name} {all}
                </span>
              </DropdownItem>
            ))}
            {!hideValueSelector && <DropdownItem divider />}

            {!hideValueSelector &&
              list.map((item) => (
                <DropdownItem
                  key={item.key}
                  onClick={() => onValue(item.key)}
                  className={classes(
                    dropdownItem({ colour }),
                    !sublist.length && selectedValue === item.key && active,
                    !!sublist.length && active
                  )}
                >
                  <span className={classes("text-uppercase")}>{item.name}</span>
                </DropdownItem>
              ))}
            {/* <DropdownItem divider /> */}
            {/* {sublist.length ? (
               <DropdownItem divider />
            ): null} */}
            {!hideValueSelector &&
              sublist.map((item) => (
                <DropdownItem
                  key={item.key}
                  onClick={() => onValue(item.key)}
                  className={classes(
                    dropdownItem({ colour }),
                    selectedValue === item.key && active
                  )}
                >
                  <span className={classes("text-uppercase")}>{item.name}</span>
                </DropdownItem>
              ))}
          </DropdownMenu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default Dropper;
