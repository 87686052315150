import { Auth } from "aws-amplify";
import React, { FC, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { classes, style } from "typestyle";
import logo from "../img/logo.svg";
import { Colour, tableBorder } from "../theme";
import { routes } from "../utils";
import "./Menu.scss";

const line = style({
  borderTop: tableBorder,
  width: "85%",
});

const listGroup = style({});
const listGroupItem = ({ background }) =>
  style({
    cursor: "pointer",
    background: "transparent",
    $nest: {
      "&:hover": {
        textShadow: `${background} 0px 0px 20px`,
      },
    },
    border: "none",
    borderRadius: "0 !important",
    borderRight: `3px transparent solid`,
    fontWeight: 500,
    color: Colour.textDark,
  });
const listGroupItemDate = ({ background }) =>
  style({
    background: "transparent",

    border: "none",
    borderRadius: "0 !important",
    borderRight: `3px transparent solid`,
    fontWeight: 500,
    color: Colour.textDark,
  });
const active = ({ color }) =>
  style({
    borderRight: `3px ${color} solid`,
    color: Colour.white,
  });

const logoutRow = style({
  position: "absolute",
  bottom: 0,
});
const formatDate = (dateStr) => {
  let d = new Date(dateStr);
  return `${`0${d.getHours()}`.slice(-2)}:${`0${d.getMinutes()}`.slice(
    -2
  )} on ${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};
type Props = RouteComponentProps & {
  username: string;
  logoUrl: string;
  updatedAt: string | null;
};
const Menu: FC<Props> = (props: Props) => {
  const isActive = (item: any) => props.history.location.pathname === item.url;
  let updatedAt = props.updatedAt ? formatDate(props.updatedAt) : null;
  return (
    <Fragment>
      <Row className={classes("mt-5 mb-4")}>
        <Col className={classes("text-center p-3")}>
          <img src={logo} className={classes("w-75")} alt="Digital Radish" />
        </Col>
      </Row>
      <Row className={classes("mt-4 ")}>
        <Col className={classes("text-center p-3")}>
          <img
            src={props.logoUrl}
            className={classes("w-75")}
            alt={props.username}
          />
        </Col>
      </Row>
      <Row className={classes("mt-4 mb-3")}>
        <Col>
          <hr className={classes(line)} />
        </Col>
      </Row>
      <Row>
        <Col className="pr-0">
          <ListGroup className={listGroup}>
            {routes.map((route) => (
              <ListGroupItem
                className={classes(
                  listGroupItem({ background: route.background }),
                  isActive(route) && active({ color: route.background }),
                  "menu-text"
                )}
                key={route.url}
                onClick={() => props.history.push(route.url)}
              >
                {route.text}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row>
      <Row className={logoutRow}>
        <Col>
          <ListGroup className={listGroup}>
            <ListGroupItem
              className={classes(listGroupItem({ background: Colour.white }))}
              onClick={() => {
                Auth.signOut();
              }}
            >
              Log out
            </ListGroupItem>
            {!!updatedAt && (
              <ListGroupItem
                className={classes(
                  listGroupItemDate({ background: Colour.white })
                )}
              >
                Updated at {updatedAt}
              </ListGroupItem>
            )}
          </ListGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(Menu);
