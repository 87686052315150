import React, { FC, Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Dropper from "../components/Dropper";
import Stats from "../components/Stats";
import { Colour } from "../theme";
import { DEFAULT_TOUCHPOINT, IAccount } from "../type";
import { formatOpportunity } from "../utils";
import AllTable from "./AllTable";
import Pies from "./Pies";

type Props = {
  accounts: IAccount[];
  remainingAccounts: IAccount[];
  setSelectedValue: any;
  selectedValue: string | null;
  setSelectedFilter: any;
  selectedFilterName: string | null;
  filters: any[];
  touchpoint: typeof DEFAULT_TOUCHPOINT;
  currency: string;
  version: number;
};

const Account: FC<Props> = ({
  remainingAccounts,
  accounts,
  selectedValue,
  setSelectedValue,
  selectedFilterName,
  setSelectedFilter,
  filters,
  currency,
  touchpoint,
  version,
}: Props) => {
  // console.log("account", accounts);
  const [contactsCount, setcontactsCount] = useState(0);
  const [callsCount, setcallsCount] = useState(0);

  const [meetingsCount, setmeetingsCount] = useState(0);
  const [accountsCount, setaccountsCount] = useState<any>(0);
  const [opportunityValue, setopportunityValue] = useState(0);
  const [account, setaccount] = useState<IAccount | null>(null);

  useEffect(() => {
    if (selectedFilterName) {
      setaccountsCount(accounts.length);
      setcontactsCount(
        accounts.reduce((count, account) => {
          count += account.contactsCount;
          return count;
        }, 0)
      );
      setcallsCount(
        accounts.reduce((count, account) => {
          count += account.callsCount;
          return count;
        }, 0)
      );
      setmeetingsCount(
        accounts.reduce((count, account) => {
          count += account.meetingsCount;
          return count;
        }, 0)
      );
      setopportunityValue(
        accounts.reduce((count, account) => {
          count += account.pipelineRevenue;
          return count;
        }, 0)
      );
    } else if (selectedValue) {
      let account =
        accounts.find((account) => account.key === selectedValue) ||
        ({} as any);
      setaccount(account);
      setaccountsCount(undefined);
      setcontactsCount(account.contactsCount || 0);
      setcallsCount(account.callsCount || 0);
      setmeetingsCount(account.meetingsCount || 0);
      setopportunityValue(account.pipelineRevenue);
    }
  }, [accounts, selectedValue, selectedFilterName]);

  return (
    <Fragment>
      <Row className={"d-none d-md-block mt-5 mb-4"}>
        <Col>
          <h1> account reporting</h1>
        </Col>
      </Row>
      <Row className={"mt-3 mb-2"}>
        <Col>
          <Dropper
            selectedValue={selectedValue}
            selectedFilterName={selectedFilterName}
            filters={filters}
            onValue={setSelectedValue}
            onFilter={setSelectedFilter}
            all="ACCOUNTS"
            list={accounts}
            sublist={remainingAccounts}
            colour={Colour.green}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={typeof accountsCount === "undefined" ? 10 : 12}>
          <Stats
            colour={Colour.green}
            accounts={accountsCount}
            contacts={contactsCount}
            calls={callsCount}
            meetings={meetingsCount}
            opportunity={formatOpportunity(opportunityValue, currency)}
          />
        </Col>
      </Row>
      {selectedFilterName ? (
        <AllTable
          touchpoint={touchpoint}
          accounts={accounts}
          currency={currency}
          version={version}
        />
      ) : (
        <Pies version={version} account={account as IAccount} />
      )}
    </Fragment>
  );
};

export default Account;
