import React, { FC, Fragment } from "react";
import PieChart from "react-minimal-pie-chart";
import { Col, Row } from "reactstrap";
import { classes, style } from "typestyle";
import { Colour, green, panel } from "../theme";

// const background = style({
//   backgroundColor: Colour.panel
// });
const texts = style({});
const large = style({
  fontSize: 20,
});
const small = style({
  fontSize: 10,
});
// const dropdownItem = style({
//   color: Colour.white,
//   $nest: {
//     "&:hover": {
//       backgroundColor: Colour.green,
//       color: Colour.white
//     }
//   }
// });
type Props = {
  rate: number;
  count: number;
  name: string;
};
const Circular: FC<Props> = (props: Props) => {
  let { name, count, rate } = props;
  try {
    if (rate > 1 || rate < 0) {
      rate = 0;
    }
    rate = Math.round(rate * 100);
    count = Math.round(count * 10) / 10;
  } catch (e) {
    console.error(e);
    count = 0;
    rate = 0;
  }

  return (
    <Fragment>
      <div className={panel}>
        <Row>
          <Col xs={12} className="mt-1 mb-2">
            <h3>{name}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <div className="p-2">
              <PieChart
                animate={false}
                animationDuration={500}
                animationEasing="ease-in-out"
                lineWidth={45}
                startAngle={-90}
                data={[
                  { value: rate, color: Colour.green },
                  { value: 100 - rate, color: Colour.white },
                ]}
              />
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            className={classes(
              texts,
              "d-flex flex-column justify-content-center"
            )}
          >
            <div className={large}>
              <h3 className="d-inline">COUNT:&nbsp;</h3>
              <h3 className={classes(green, "d-inline")}>{count}</h3>
            </div>
            <div className={small}>
              <span>RATE:&nbsp;</span>
              <span className={classes(green)}>{rate}%</span>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Circular;
