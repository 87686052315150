import { style } from "typestyle"

export enum Colour {
    white = '#ffffff',
    blue = '#20235b',
    darkblue = '#1d1d1c',
    lightblue = '#49beed',
    panel = '#62637b',
    green = '#80c342',
    textDark = '#797b9d',
    greyLight = '#81818f',
    greyDark = '#62637b',
    orange = '#fcaf26'
}

export const green = style({
    color: Colour.green
})
export const bgGreen = style({
    backgroundColor: Colour.green
})

export const panelBorderRadius = 5
export const panel = style({
    backgroundColor: Colour.panel,
    color: Colour.white,
    borderRadius: panelBorderRadius,
    padding: 10,
    // margin: 10
    marginTop: 10,
    marginBottom: 10
})

export enum Structure {
    borderRadius = '5px',
    tablePadding = 10,
    tableFontSize = 14,
    
}

export const apiName = 'api4f41a08d'

export const tableBorder = '2px white solid'

export const TableStructure = {

    tableContainer: style({
       
        backgroundColor: Colour.greyDark
    }),

    table: style({
        border:tableBorder,
        fontSize: Structure.tableFontSize,
        backgroundColor: Colour.greyDark,
        $nest: {
            'th,td': {
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                padding: Structure.tablePadding,
                color: Colour.white
            },
            // td:{
            //     padding:  Structure.tablePadding
            // }
        }
    }),
    odd: style({
        backgroundColor: Colour.greyLight
    }),
    vertLine: style({
        borderLeft: tableBorder
    }),
    topHeader: style({
        backgroundColor: Colour.green,
    }),
}