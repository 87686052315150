import Amplify, { API, Auth, Storage } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import { classes, style } from "typestyle";
import Account from "./account/Account";
import "./App.scss";
import CustomSignIn from "./auth/CustomSignIn";
import awsconfig from "./aws-exports";
import { filtersBase, filtersV2, filtersV4, filtersV5 } from "./filters";
import Menu from "./menu/Menu";
import NavTop from "./menu/NavTop";
import Overall from "./overall/Overall";
import Personas from "./personas/Personas";
import { apiName, Colour } from "./theme";
import {
  DEFAULT_TOUCHPOINT,
  FILTER_NAME_ALL,
  IAccount,
  IPersona,
  IOverall,
} from "./type";

Amplify.configure(awsconfig);

// Hub.listen('auth', data => {
//   let { payload } = data
//   switch (payload.event) {
//     case "signIn":

//   }
// })

const container = style({
  background: `linear-gradient(${Colour.blue},${Colour.darkblue})`,
});
const row = style({
  height: "100%",
});
const menu = style({
  background: Colour.blue,
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
});
const spinnerContainer = style({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

type Props = {};
type State = {
  touchpoint: typeof DEFAULT_TOUCHPOINT;
  hasData: boolean;
  overall: IOverall;
  // overallSplit: any;
  accounts: IAccount[];
  remainingAccounts: IAccount[];
  personas: IPersona[];
  remainingPersonas: IPersona[];
  filters: any[];
  selectedFilterName: string | null;
  selectedValue: string | null;
  collapsed: boolean;
  logoKey: string;
  username: string;
  logoUrl: string;
  version: number;
  currency: string;
  updatedAt: string | null;
};
class App extends Component<Props, State> {
  accounts: IAccount[] = [];
  personas: IPersona[] = [];
  // overall: any = {};
  overall: any = {};
  overallSplit: {
    [key: string]: IOverall;
  } = {};
  constructor(props: any) {
    super(props);
    this.state = {
      touchpoint: DEFAULT_TOUCHPOINT,
      username: "",
      hasData: false,
      //@ts-ignore
      overall: {},
      // overallSplit: {},
      accounts: [],
      remainingAccounts: [],
      personas: [],
      remainingPersonas: [],
      filters: [],
      selectedFilterName: FILTER_NAME_ALL,
      selectedValue: null,
      collapsed: true,
      logoKey: "",
      logoUrl: "",
      version: 1,
      currency: "£",
      updatedAt: null,
    };
  }
  isShownWithFilter = (account) => {
    if (this.state.selectedFilterName === FILTER_NAME_ALL) {
      return true;
    }
    return account.filterType.includes(this.state.selectedFilterName as string);
  };
  addKeys = (things) =>
    things.map((thing, i) => ({
      ...thing,
      key: String(i),
    }));
  componentDidMount = async () => {
    let { username } = await Auth.currentAuthenticatedUser();
    let data = await API.get(apiName, `/data/${username}`, {});
    console.log(data);

    let logoUrl = "";
    if (data.logoKey && data.logoKey.length) {
      logoUrl = (await Storage.get(data.logoKey)) as string;
    }
    this.accounts = ((data.accounts as IAccount[]) || [])
      .map((account, i) => ({
        ...account,
        key: String(i),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    const version =
      data.version ??
      (data.overall.data11 ? 2 : data.touchpoint?.long?.touchpoint7 ? 3 : 1);
    if ([2, 4, 5, 6].includes(version)) {
      this.overallSplit = data.overall;
    } else {
      this.overall = data.overall;
    }
    // this.overall = [2,4].includes(version) ? {} : data.overall;
    // this.overallSplit = ![2,4].includes(version) ? data.overall : {};
    this.personas = (data.personas || []).map((persona, i) => ({
      ...persona,
      accountKey: this.accounts.find(
        (account) =>
          account.name?.toLowerCase() === persona.account?.toLowerCase()
      )?.key,
      key: String(i),
    }));

    const filters = (() => {
      if (data.filters) {
        return data.filters;
      }
      switch (version) {
        case 2:
          return filtersV2;
        case 4:
          return filtersV4;
        case 5:
        case 6:
          return filtersV5;
        default:
          return filtersBase;
      }
    })();

    this.setState({
      currency: data.currency || "£",
      logoUrl,
      username,
      hasData: true,
      overall: this.overall,
      filters,
      version,

      personas: this.personas,
      touchpoint: data.touchpoint || DEFAULT_TOUCHPOINT,
      logoKey: data.logoKey || "",
      updatedAt: data.updatedAt,
    });
    this.refreshCalced();
    console.log("mount", this.state);
  };
  componentDidUpdate = () => {
    console.log("update", this.state);
  };

  refreshCalced = () => {
    let accounts: any[] = this.accounts;
    let remainingAccounts: any[] = [];

    let overall = this.overall;

    if (this.state.selectedFilterName !== null) {
      accounts = this.accounts.filter(this.isShownWithFilter);

      remainingAccounts = this.accounts.filter(
        (account) => !this.isShownWithFilter(account)
      );

      if (Object.keys(this.overallSplit).length) {
        let overallSplitKeys: string[] =
          this.state.filters.find(
            (filter) => filter.name === this.state.selectedFilterName
          )?.overallSplit || [];
        overall = {};
        console.log("overallSplitKeys", overallSplitKeys);
        let totalAccountCount = overallSplitKeys.reduce(
          (acc, overallSplitKey) => {
            let data = this.overallSplit[overallSplitKey];
            acc += data.totalAccountCount;
            return acc;
          },
          0
        );
        console.log("totalAccountCount", totalAccountCount);
        let totalContactCount = overallSplitKeys.reduce(
          (acc, overallSplitKey) => {
            let data = this.overallSplit[overallSplitKey];
            acc += data.totalContactCount;
            return acc;
          },
          0
        );
        overallSplitKeys.forEach((overallSplitKey) => {
          let data = this.overallSplit[overallSplitKey];
          Object.keys(data).forEach((key) => {
            if (key.includes("Rate")) {
              if (!overall[key]) {
                overall[key] = 0;
              }
              // make average
              if (key.includes("Account")) {
                let weighting = data.totalAccountCount / totalAccountCount;

                overall[key] += (weighting || 0) * data[key];
              } else if (key.includes("Contact")) {
                let weighting = data.totalContactCount / totalContactCount;
                overall[key] += (weighting || 0) * data[key];
              }
            } else {
              if (!overall[key]) {
                overall[key] = 0;
              }
              overall[key] += +data[key];
            }
          });
          // if (this.state.selectedFilterName === FILTER_NAME_ALL) {

          // } else {
          //   overall = data;
          // }
        });
      }
    }

    this.setState({ accounts, remainingAccounts, overall });
  };

  render() {
    if (!this.state.hasData) {
      return (
        <Container
          fluid
          className={classes(container, style({ height: "100vh" }))}
        >
          <Row className={row}>
            <Col className={spinnerContainer}>
              <Spinner />
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Router>
        <NavTop logoUrl={this.state.logoUrl} username={this.state.username} />
        <Container fluid className={classes(container, "root-container")}>
          <Row className={row}>
            <Col md={3} className={classes(menu, "d-none d-md-block")}>
              <Menu
                logoUrl={this.state.logoUrl}
                username={this.state.username}
                updatedAt={this.state.updatedAt}
              />
            </Col>
            <Col
              xs={12}
              md={9}
              className={classes(
                "h-100",
                style({
                  overflowY: "auto",
                })
              )}
            >
              <Route
                path="/"
                exact
                render={(props) => (
                  <Overall
                    currency={this.state.currency}
                    version={this.state.version}
                    filters={this.state.filters}
                    selectedFilterName={this.state.selectedFilterName}
                    selectedValue={this.state.selectedValue}
                    setSelectedFilter={(selectedFilterName) =>
                      this.setState(
                        { selectedFilterName, selectedValue: null },
                        () => this.refreshCalced()
                      )
                    }
                    setSelectedValue={(selectedValue) =>
                      this.setState(
                        { selectedValue, selectedFilterName: null },
                        () => this.refreshCalced()
                      )
                    }
                    remainingAccounts={this.state.remainingAccounts}
                    touchpoint={this.state.touchpoint}
                    overall={this.state.overall}
                    accounts={this.state.accounts}
                    personas={this.state.personas}
                  />
                )}
              />
              <Route
                path="/account"
                render={(props) => (
                  <Account
                    version={this.state.version}
                    currency={this.state.currency}
                    touchpoint={this.state.touchpoint}
                    filters={this.state.filters}
                    accounts={this.state.accounts}
                    remainingAccounts={this.state.remainingAccounts}
                    selectedFilterName={this.state.selectedFilterName}
                    selectedValue={this.state.selectedValue}
                    setSelectedFilter={(selectedFilterName) =>
                      this.setState(
                        { selectedFilterName, selectedValue: null },
                        () => this.refreshCalced()
                      )
                    }
                    setSelectedValue={(selectedValue) =>
                      this.setState(
                        { selectedValue, selectedFilterName: null },
                        () => this.refreshCalced()
                      )
                    }
                  />
                )}
              />
              <Route
                path="/contact"
                render={(props) => (
                  <Personas
                    version={this.state.version}
                    currency={this.state.currency}
                    filters={this.state.filters}
                    accounts={this.state.accounts}
                    remainingAccounts={this.state.remainingAccounts}
                    selectedFilterName={this.state.selectedFilterName}
                    selectedValue={this.state.selectedValue}
                    setSelectedFilter={(selectedFilterName) =>
                      this.setState(
                        { selectedFilterName, selectedValue: null },
                        () => this.refreshCalced()
                      )
                    }
                    setSelectedValue={(selectedValue) =>
                      this.setState(
                        { selectedValue, selectedFilterName: null },
                        () => this.refreshCalced()
                      )
                    }
                    personas={this.state.personas}
                  />
                )}
              />
            </Col>
          </Row>
        </Container>
      </Router>
    );
  }
}

const AuthenticatedApp = withAuthenticator(App, false, [<CustomSignIn />]);

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  // constructor(props) {
  //   super(props);
  //   this.state = { hasError: false };
  // }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container
          fluid
          className={classes(container, style({ height: "100vh" }))}
        >
          <Row className={row}>
            <Col className={spinnerContainer}>
              Oops :( Something went wrong. Please contact us.
            </Col>
          </Row>
        </Container>
      );
    }

    return this.props.children;
  }
}

class ErrorBoundedAuthenticatedApp extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <ErrorBoundary>
        <AuthenticatedApp />
      </ErrorBoundary>
    );
  }
}

export default ErrorBoundedAuthenticatedApp;
