/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "campaign-dashboard-prod",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "campaign22a675d8c42b4eaf9f91934608303f8a82140-prod",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:2dba370e-26aa-4b8a-bc56-df63834ebc62",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_qgiRdEuPF",
    "aws_user_pools_web_client_id": "4qtafakrbmqap6d6c5642ua5oq",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "api4f41a08d",
            "endpoint": "https://wd94dvtcg4.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_content_delivery_bucket": "campaign-20200124131447-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "https://d2oyhg7og5yzri.cloudfront.net"
};


export default awsmobile;
