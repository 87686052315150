import React, { FC, Fragment } from "react";
import PieChart from "react-minimal-pie-chart";
import { Col, Row } from "reactstrap";
import { Colour, panel } from "../theme";
import { abbreviateNumber } from "../utils";

type Props = {
  current: number;
  target: number;
  currency: string;
  version: number;
};

const Pipeline: FC<Props> = ({
  current,
  target,
  currency = "£",
  version,
}: Props) => {
  let fract = target > 0 ? Math.round((100 * current) / target) : 0;
  fract = Math.min(fract, 100);

  const format = (value) => {
    return `${currency}${abbreviateNumber(value, 1)}`;
  };
  return (
    <Fragment>
      <div className={panel}>
        <Row className="mt-1 mb-2">
          <Col xs={12}>
            <h3>pipeline revenue</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className="p-3">
              <PieChart
                animate={false}
                animationDuration={500}
                animationEasing="ease-in-out"
                lineWidth={45}
                startAngle={-90}
                data={[
                  { value: fract, color: Colour.lightblue },
                  { value: 100 - fract, color: Colour.white },
                ]}
              />
            </div>
          </Col>
          <Col xs={6} className="d-flex flex-column justify-content-center">
            <div>
              <h3>{format(current)}</h3>
            </div>
            {version !== 6 && (
              <div>
                <h6>Target {format(target)}</h6>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Pipeline;
