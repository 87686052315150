export const FILTER_NAME_ALL = "ALL";
export const DEFAULT_TOUCHPOINT = {
  long: {
    touchpoint1: "LI Request Accepted",
    touchpoint2: "LI Teaser Content",
    touchpoint3: "Email Teaser Content",
    touchpoint4: "Direct Mail",
    touchpoint5: "Follow-Up Email W/ E-Passport And Case Study",
    touchpoint6: "Follow-Up Call",
    touchpoint7: "Follow-Up",
  },
  short: {
    touchpoint1: "LI ACCEPTED",
    touchpoint2: "LI TEASER",
    touchpoint3: "EMAIL TEASER",
    touchpoint4: "DIRECT MAIL",
    touchpoint5: "EMAIL FOLLOW",
    touchpoint6: "CALL FOLLOW",
    touchpoint7: "FOLLOW-UP",
  },
};

export interface IAccount {
  key: string;
  filterType: string[];
  industry: string;
  wonDealValue: number;
  wonDeals: number;

  callsCount: number;
  meetingsCount: number;
  opportunitiesCount: number;
  contactsCount: number;

  overallRate: number;
  touchpoint2Rate: number;
  pipelineRevenue: number;
  touchpoint5Rate: number;
  touchpoint3Count: number;
  touchpoint4Rate: number;
  touchpoint6Count: number;

  touchpoint3Rate: number;
  touchpoint1Count: number;
  touchpoint1Rate: number;
  touchpoint4Count: number;
  name: string;
  touchpoint6Rate: number;
  touchpoint5Count: number;
  touchpoint2Count: number;

  touchpoint7Count: number;
  touchpoint7Rate: number;

  nurtureAccount: string | null;
  hotAccount: string | null;
}

export interface IOverall {
  emailsAccountCount: number;
  emailsAccountRate: number;
  emailsContactCount: number;
  emailsContactRate: number;
  emailsName?: string;

  callsAccountCount: number;
  callsAccountRate: number;
  callsContactCount: number;
  callsContactRate: number;
  callsName?: string;

  meetingsAccountCount: number;
  meetingsAccountRate: number;
  meetingsContactCount: number;
  meetingsContactRate: number;
  meetingsName?: string;

  opportunitiesAccountCount: number;
  opportunitiesAccountRate: number;
  opportunitiesContactCount: number;
  opportunitiesContactRate: number;
  opportunitiesName?: string;

  pipeline: number;
  pipelineName?: string;

  target: number;
  targetName?: string;

  touchpoint1AccountCount: number;
  touchpoint3AccountCount: number;
  totalAccountCount: number;
  touchpoint2AccountCount: number;
  touchpoint5AccountCount: number;
  touchpoint6AccountRate: number;
  touchpoint4AccountCount: number;
  touchpoint4ContactRate: number;
  touchpoint2ContactCount: number;
  touchpoint3ContactCount: number;
  touchpoint4ContactCount: number;
  touchpoint6AccountCount: number;
  touchpoint5ContactCount: number;
  touchpoint6ContactCount: number;
  touchpoint3AccountRate: number;
  touchpoint3ContactRate: number;
  touchpoint1AccountRate: number;
  touchpoint4AccountRate: number;
  totalContactCount: number;
  touchpoint6ContactRate: number;
  touchpoint1ContactCount: number;
  touchpoint2ContactRate: number;
  touchpoint2AccountRate: number;
  touchpoint1ContactRate: number;
  touchpoint5AccountRate: number;
  touchpoint5ContactRate: number;
  numberWonDeals: number;
  valueofWonDeals: number;

  touchpoint7AccountRate: number;
  touchpoint7AccountCount: number;
  touchpoint7ContactCount: number;
  touchpoint7ContactRate: number;
}

export interface IPersona {
  key: string;
  accountKey: string;
  firstName: string;
  lastName: string;
  title: string;
  touchpoint1: string;
  touchpoint2: string;
  touchpoint3: string;
  touchpoint4: string;
  touchpoint5: string;
  touchpoint6: string;
  touchpoint7: string;
  extraTouchpoint: string;
  channel: string;
  account: string;
  touchpoint3Notes: string;
  touchpoint4Notes: string;
  hotContact: string;
}

/*

  generateSelctOptions = () => {
    let fields = ["filterType", "industry"];
    let filters: {
      name: string;
      key: string;
      filterType?: string;
      industry?: string;
    }[] = [
      {
        name: "ALL",
        key: "0",
      },
      {
        name: "1-1",
        filterType: "1-1",
        key: "1",
      },
      {
        name: "1-Few",
        filterType: "1-Few",
        key: "2",
      },
      {
        name: "1-Few Retail",
        filterType: "1-Few",
        industry: "Retail",
        key: "3",
      },
      {
        name: "1-Few Finance",
        filterType: "1-Few",
        industry: "Finance",
        key: "4",
      },
    ];
    // this.accounts.forEach((account) => {
    //   let filter = {
    //     name: "",
    //   };
    //   fields.forEach((field, i) => {
    //     if (account[field]) {
    //       filter[field] = account[field];
    //       filter.name += `${i > 0 ? " " : ""}${account[field]}`;
    //     }
    //   });

    //   filters.push(filter);
    // });
    // filters = filters.filter(
    //   (obj, index, self) =>
    //     index ===
    //     self.findIndex((t) => JSON.stringify(t) === JSON.stringify(obj))
    // ).filter(filter => filter.name !== '');

    // filters.unshift({
    //   name: FILTER_NAME_ALL,
    // });

    this.setState({ filters });
  };
*/
