import React, { FC, Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { classes, style } from "typestyle";
import Callout from "../components/Callout";
import accountsIcon from "../img/accounts.svg";
import contacts from "../img/contacts.svg";
import {
  DEFAULT_TOUCHPOINT,
  IAccount,
  IOverall,
  IPersona,
  FILTER_NAME_ALL,
} from "../type";
import Dashboard from "./Dashboard";
import Engagement from "./Engagement";
import Lists from "./Lists";
import Pipeline from "./Pipeline";
import Dropper from "../components/Dropper";
import { Colour } from "../theme";

const title = style({});

const getMaxAccountTP = (account: IAccount) => {
  for (var i = 8; i >= 1; i--) {
    if (account[`touchpoint${i}Count`]) {
      return i;
    }
  }
  return 0;
};
// const getMaxAccountTPV3 = (account :IAccount) => {
//   let highestTP = 0
//   for (var i = 7; i >= 1; i--) {
//     if (Number(account[`touchpoint${i}Count`]) > 0) {
//       highestTP = i
//     }
//   }
// }
const getMaxPersonaTP = (persona: IPersona) => {
  for (var i = 8; i >= 1; i--) {
    if (persona[`touchpoint${i}`]) {
      return i;
    }
  }
  return 0;
};
type Props = {
  setSelectedValue: any;
  selectedValue: string | null;
  setSelectedFilter: any;
  selectedFilterName: string | null;
  filters: any[];

  overall: IOverall;

  accounts: IAccount[];
  remainingAccounts: IAccount[];
  personas: IPersona[];
  version: number;
  touchpoint: typeof DEFAULT_TOUCHPOINT;
  currency: string;
};

const Overall: FC<Props> = ({
  overall,
  accounts,
  personas,
  touchpoint,
  remainingAccounts,
  selectedValue,
  setSelectedValue,
  selectedFilterName,
  setSelectedFilter,
  version,
  filters,
  currency,
}: Props) => {
  const [accountsCount, setaccountsCount] = useState<any>(0);
  const [contactsCount, setcontactsCount] = useState(0);
  useEffect(() => {
    if (filters.length > 1 && !selectedFilterName) {
      setSelectedFilter(FILTER_NAME_ALL);
    }
  }, [filters, selectedFilterName, setSelectedFilter]);
  useEffect(() => {
    setaccountsCount(accounts.length);
    setcontactsCount(
      accounts.reduce((count, account) => {
        count += account.contactsCount;
        return count;
      }, 0)
    );
  }, [accounts]);
  personas = personas.filter((persona) =>
    accounts.find((account) => account.key === persona.accountKey)
  );

  let nutureAccounts: any[] = [];

  if (version > 1) {
    nutureAccounts = accounts
      .filter((account) => account.nurtureAccount === "Y")
      .map((account) => ({
        key: account.key,
        name: account.name,
        touchpointReached: getMaxAccountTP(account),
      }));
  } else {
    nutureAccounts = accounts
      .map((account) => ({
        key: account.key,
        name: account.name,
        touchpointReached: getMaxAccountTP(account),
      }))
      .filter((account) => account.touchpointReached <= 2);
  }

  let hotPersonas: any[] = [];

  if (version > 1) {
    hotPersonas = personas
      .filter((persona) => persona.hotContact === "Y")
      .map((persona) => ({
        key: persona.key,
        account: persona.account,
        name: `${persona.firstName} ${persona.lastName}`,
        title: persona.title,
        touchpointReached: getMaxPersonaTP(persona),
      }));
  } else {
    hotPersonas = personas
      .map((persona) => ({
        key: persona.key,
        name: `${persona.firstName} ${persona.lastName}`,
        title: persona.title,
        touchpointReached: getMaxPersonaTP(persona),
      }))
      .filter((persona) => persona.touchpointReached >= 3);
  }

  let hot = personas.reduce((hot, persona) => {
    if (!hot[persona.accountKey]) {
      hot[persona.accountKey] = 0;
    }
    if (getMaxPersonaTP(persona) > 1) {
      hot[persona.accountKey]++;
    }
    return hot;
  }, {});
  let hotAccounts: any[] = [];
  if (version > 1) {
    hotAccounts = accounts
      .filter(
        (account) =>
          account.hotAccount === "Y" || Number(account.hotAccount) > 3
      )
      .map((account) => ({
        key: account.key,
        name: account.name,
        touchpointReached: getMaxAccountTP(account),
        quantityEngaged: hot[account.key],
      }));
  } else {
    hotAccounts = accounts
      .map((account) => ({
        key: account.key,
        name: account.name,
        touchpointReached: getMaxAccountTP(account),
        quantityEngaged: hot[account.key],
      }))
      .filter((account) => account.quantityEngaged >= 3);
  }

  return (
    <Fragment>
      <Row className="d-none d-md-block mt-5 mb-4">
        <Col xs={12}>
          <h1 className={classes(title, "mt-5 ")}>
            OVERALL CAMPAIGN REPORTING
          </h1>
        </Col>
      </Row>
      {filters.length > 1 ? (
        <Row className={"mt-3 mb-2"}>
          <Col>
            <Dropper
              selectedValue={selectedValue}
              selectedFilterName={selectedFilterName}
              filters={filters}
              onValue={setSelectedValue}
              onFilter={setSelectedFilter}
              all="OVERALL"
              list={accounts}
              sublist={remainingAccounts}
              colour={Colour.lightblue}
              hideValueSelector={true}
            />
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col xs={12} lg={5}>
          <Row>
            <Col xs={6}>
              <Callout
                icon={accountsIcon}
                quantity={accountsCount}
                name="account"
              />
            </Col>
            <Col xs={6}>
              <Callout
                icon={contacts}
                quantity={contactsCount}
                name="contact"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8} md={8} lg={12}>
              <Pipeline
                current={overall.pipeline}
                target={overall.target}
                currency={currency}
                version={version}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={7}>
          <Dashboard
            overall={overall}
            pipeline={overall.pipeline}
            currency={currency}
            version={version}
          />
        </Col>
      </Row>
      {version === 2 && selectedFilterName === FILTER_NAME_ALL ? null : (
        <Row>
          <Col>
            <Engagement
              touchpoint={touchpoint}
              overall={overall}
              version={version}
            />
          </Col>
        </Row>
      )}

      <Lists
        version={version}
        hotAccounts={hotAccounts}
        nutureAccounts={nutureAccounts}
        hotPersonas={hotPersonas}
      />
    </Fragment>
  );
};

export default Overall;
