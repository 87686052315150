import React, { FC, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { classes, style } from "typestyle";
import { Colour, panel, TableStructure } from "../theme";
import { IAccount, DEFAULT_TOUCHPOINT } from "../type";
import { formatPipeline, formatRate } from "../utils";

const tableContainer = classes(
  TableStructure.tableContainer,
  style({
    overflow: "auto",
    width: "100%",
  })
);
const topHeader = style({
  backgroundColor: Colour.green,
});

type Props = {
  accounts: IAccount[];
  touchpoint: typeof DEFAULT_TOUCHPOINT;
  currency: string;
  version: number;
};

const AllTable: FC<Props> = ({
  accounts,
  touchpoint,
  currency,
  version,
}: Props) => (
  <Fragment>
    <Row>
      <Col>
        <div className={classes(panel, tableContainer, " ")}>
          <Row className="mt-1 mb-2">
            <Col>
              <h3>abm campaign Dashboard</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <table className={classes(TableStructure.table, "text-center")}>
                <thead>
                  <tr className={topHeader}>
                    <th></th>
                    <th>pipeline revenue</th>
                    {version === 2 && (
                      <Fragment>
                        <th>won deals</th>
                        <th>won deal value</th>
                      </Fragment>
                    )}

                    <th>contacts</th>
                    <th colSpan={2}>touchpoint 1</th>
                    <th colSpan={2}>touchpoint 2</th>
                    <th colSpan={2}>touchpoint 3</th>
                    <th colSpan={2}>
                      touchpoint 4{[3].includes(version) ? "&5" : ""}
                    </th>

                    {![3, 4].includes(version) && (
                      <>
                        <th colSpan={2}>touchpoint 5</th>
                        {![6, 5].includes(version) && (
                          <th colSpan={2}>touchpoint 6</th>
                        )}
                      </>
                    )}
                    {version === 2 && <th colSpan={2}>touchpoint 7</th>}

                    <th>Overall Engagement</th>
                    <th>Sales {version === 3 ? "Discussions" : "Calls"}</th>
                    <th>Meetings Booked</th>
                    <th>Opportunities</th>
                  </tr>
                  <tr className={classes(topHeader)}>
                    <th colSpan={version === 2 ? 5 : 3}></th>

                    <th colSpan={2}>{touchpoint.short.touchpoint1}</th>
                    <th colSpan={2}>{touchpoint.short.touchpoint2}</th>
                    <th colSpan={2}>{touchpoint.short.touchpoint3}</th>
                    <th colSpan={2}>{touchpoint.short.touchpoint4}</th>
                    {![3, 4].includes(version) && (
                      <>
                        <th colSpan={2}>{touchpoint.short.touchpoint5}</th>
                        {![6, 5].includes(version) && (
                          <th colSpan={2}>{touchpoint.short.touchpoint6}</th>
                        )}
                      </>
                    )}

                    {version === 2 && (
                      <th colSpan={2}>{touchpoint.short.touchpoint7}</th>
                    )}
                    <th colSpan={4}></th>
                  </tr>
                  <tr>
                    <th colSpan={15}></th>
                    {version === 2 && (
                      <Fragment>
                        <th colSpan={2}></th>
                        <th colSpan={2}></th>
                      </Fragment>
                    )}
                    {![3, 4].includes(version) && (
                      <Fragment>
                        <th colSpan={[6, 5].includes(version) ? 2 : 4}></th>
                      </Fragment>
                    )}
                  </tr>
                  <tr>
                    <th></th>
                    <th className={TableStructure.vertLine}></th>
                    <th className={TableStructure.vertLine}></th>
                    {version === 2 && (
                      <Fragment>
                        <th></th>
                        <th className={TableStructure.vertLine}></th>
                      </Fragment>
                    )}

                    <th className={TableStructure.vertLine}>count</th>
                    <th>rate</th>
                    <th className={TableStructure.vertLine}>count</th>
                    <th>rate</th>
                    <th className={TableStructure.vertLine}>count</th>
                    <th>rate</th>
                    <th className={TableStructure.vertLine}>count</th>
                    <th>rate</th>
                    {![3, 4].includes(version) && (
                      <>
                        <th className={TableStructure.vertLine}>count</th>
                        <th>rate</th>
                        {![6, 5].includes(version) && (
                          <>
                            <th className={TableStructure.vertLine}>count</th>
                            <th>rate</th>
                          </>
                        )}
                      </>
                    )}

                    {version === 2 && (
                      <>
                        <th className={TableStructure.vertLine}>count</th>
                        <th>rate</th>
                      </>
                    )}

                    <th className={TableStructure.vertLine}>rate</th>
                    <th className={TableStructure.vertLine}>count</th>
                    <th className={TableStructure.vertLine}>count</th>
                    <th className={TableStructure.vertLine}>count</th>
                  </tr>
                </thead>
                <tbody>
                  {accounts
                    .filter((account) => account.name !== "Total")
                    .map((account, i) => (
                      <tr
                        key={account.key}
                        className={classes(i % 2 === 1 && TableStructure.odd)}
                      >
                        <td>{account.name}</td>
                        <td className={TableStructure.vertLine}>
                          {formatPipeline(account.pipelineRevenue, currency)}
                        </td>

                        {version === 2 && (
                          <Fragment>
                            <td className={TableStructure.vertLine}>
                              {account.wonDeals || 0}
                            </td>
                            <td>
                              {formatPipeline(account.wonDealValue, currency)}
                            </td>
                          </Fragment>
                        )}

                        <td className={TableStructure.vertLine}>
                          {Number(account.contactsCount)}
                        </td>
                        <td className={TableStructure.vertLine}>
                          {Number(account.touchpoint1Count)}
                        </td>
                        <td>{formatRate(account.touchpoint1Rate)}</td>
                        <td className={TableStructure.vertLine}>
                          {Number(account.touchpoint2Count)}
                        </td>
                        <td>{formatRate(account.touchpoint2Rate)}</td>
                        <td className={TableStructure.vertLine}>
                          {Number(account.touchpoint3Count)}
                        </td>
                        <td>{formatRate(account.touchpoint3Rate)}</td>
                        <td className={TableStructure.vertLine}>
                          {Number(account.touchpoint4Count)}
                        </td>
                        <td>{formatRate(account.touchpoint4Rate)}</td>

                        {![3, 4].includes(version) && (
                          <>
                            <td className={TableStructure.vertLine}>
                              {Number(account.touchpoint5Count) || 0}
                            </td>
                            <td>{formatRate(account.touchpoint5Rate)}</td>
                            {![6, 5].includes(version) && (
                              <>
                                <td className={TableStructure.vertLine}>
                                  {Number(account.touchpoint6Count) || 0}
                                </td>
                                <td>{formatRate(account.touchpoint6Rate)}</td>
                              </>
                            )}
                          </>
                        )}

                        {version === 2 && (
                          <>
                            <td className={TableStructure.vertLine}>
                              {typeof account.touchpoint7Count === "undefined"
                                ? "-"
                                : Number(account.touchpoint7Count)}
                            </td>
                            <td>
                              {typeof account.touchpoint7Count === "undefined"
                                ? "-"
                                : formatRate(account.touchpoint7Rate)}
                            </td>
                          </>
                        )}
                        <td className={TableStructure.vertLine}>
                          {formatRate(account.overallRate)}
                        </td>

                        <td className={TableStructure.vertLine}>
                          {Number(account.callsCount)}
                        </td>
                        <td className={TableStructure.vertLine}>
                          {Number(account.meetingsCount)}
                        </td>
                        <td className={TableStructure.vertLine}>
                          {Number(account.opportunitiesCount)}
                        </td>
                      </tr>
                    ))}
                  {[3, 4].includes(version) && (
                    <>
                      <tr>
                        <th colSpan={15}></th>
                      </tr>
                      <tr className={classes(TableStructure.topHeader)}>
                        <td>Total</td>
                        <td></td>

                        <td></td>
                        <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.touchpoint1Count,
                              0
                            )
                          )}
                        </td>
                        <td> </td>
                        <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.touchpoint2Count,
                              0
                            )
                          )}
                        </td>
                        <td> </td>
                        <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.touchpoint3Count,
                              0
                            )
                          )}
                        </td>
                        <td> </td>
                        <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.touchpoint4Count,
                              0
                            )
                          )}
                        </td>
                        {/* <td> </td> */}
                        {/* <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.touchpoint5Count,
                              0
                            )
                          )}
                        </td>
                        <td> </td>
                        <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.touchpoint6Count,
                              0
                            )
                          )}
                        </td>
                        <td> </td> */}

                        {/* <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.touchpoint7Count,
                              0
                            )
                          )}
                        </td> */}
                        <td> </td>
                        <td></td>

                        <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.callsCount,
                              0
                            )
                          )}
                        </td>
                        <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) => acc + account.meetingsCount,
                              0
                            )
                          )}
                        </td>
                        <td>
                          {Number(
                            accounts.reduce(
                              (acc, account) =>
                                acc + account.opportunitiesCount,
                              0
                            )
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                  {/* {accounts
                    .filter((account) => account.name === "Total")
                    .map((account) => (
                      <tr
                        key={account.key}
                        className={classes(TableStructure.topHeader)}
                      >
                        <td>{account.name}</td>
                        <td></td>
                        {version === 2 && (
                          <Fragment>
                            <td></td>
                            <td></td>
                          </Fragment>
                        )}

                        <td></td>
                        <td>{Number(account.touchpoint1Count)}</td>
                        <td> </td>
                        <td>{Number(account.touchpoint2Count)}</td>
                        <td> </td>
                        <td>{Number(account.touchpoint3Count)}</td>
                        <td> </td>
                        <td>{Number(account.touchpoint4Count)}</td>
                        <td> </td>
                        <td>{Number(account.touchpoint5Count)}</td>
                        <td> </td>
                        <td>{Number(account.touchpoint6Count)}</td>
                        <td> </td>

                        {version === 3 && (
                          <>
                            <td>{Number(account.touchpoint7Count)}</td>
                            <td> </td>
                          </>
                        )}
                        <td></td>

                        <td>{Number(account.callsCount)}</td>
                        <td>{Number(account.meetingsCount)}</td>
                        <td>{Number(account.opportunitiesCount)}</td>
                      </tr>
                    ))} */}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </Fragment>
);

export default AllTable;
