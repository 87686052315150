import React, { FC, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { classes, style } from "typestyle";
import { Colour, panel, TableStructure } from "../theme";
import { IOverall } from "../type";
import { formatPipeline, formatRate } from "../utils";

const topHeader = style({
  backgroundColor: Colour.lightblue,
});

type Props = {
  overall: IOverall;
  pipeline: number;
  currency: string;
  version: number;
};
const Dashboard: FC<Props> = ({
  overall,
  pipeline,
  currency,
  version,
}: Props) => {
  return (
    <Fragment>
      <div className={classes(panel, " ")}>
        <Row className="mt-1 mb-2">
          <Col>
            <h3>abm campaign Dashboard</h3>
          </Col>
        </Row>
        <Row className="d-none d-md-block">
          <Col
            className={style({
              overflow: "auto",
            })}
          >
            <div>
              <table className={classes(TableStructure.table, "w-100")}>
                <thead>
                  <tr className={topHeader}>
                    <th></th>
                    <th className="text-center" colSpan={2}>
                      accounts
                    </th>
                    <th className="text-center" colSpan={2}>
                      contacts
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className="text-center">count</th>
                    <th className="text-center">rate</th>
                    <th className="text-center">count</th>
                    <th className="text-center">rate</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof overall.emailsAccountCount !== "undefined" && (
                    <tr>
                      <td>
                        {overall.emailsName
                          ? overall.emailsName
                          : "Total Emails"}
                      </td>
                      <td className="text-center">
                        {overall.emailsAccountCount || 0}
                      </td>
                      <td className="text-center">
                        {formatRate(overall.emailsAccountRate)}
                      </td>
                      <td className="text-center">
                        {overall.emailsContactCount || 0}
                      </td>
                      <td className="text-center">
                        {formatRate(overall.emailsContactRate)}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      {overall.callsName
                        ? overall.callsName
                        : "Total Sales Discussions"}
                    </td>
                    <td className="text-center">
                      {overall.callsAccountCount || 0}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.callsAccountRate)}
                    </td>
                    <td className="text-center">
                      {overall.callsContactCount || 0}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.callsContactRate)}
                    </td>
                  </tr>
                  <tr className={TableStructure.odd}>
                    <td>
                      {overall.meetingsName
                        ? overall.meetingsName
                        : "Total Meetings Booked"}
                    </td>
                    <td className="text-center">
                      {overall.meetingsAccountCount || 0}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.meetingsAccountRate)}
                    </td>
                    <td className="text-center">
                      {overall.meetingsContactCount || 0}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.meetingsContactRate)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {overall.opportunitiesName
                        ? overall.opportunitiesName
                        : "Total Opportunities"}
                    </td>
                    <td className="text-center">
                      {overall.opportunitiesAccountCount || 0}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.opportunitiesAccountRate)}
                    </td>
                    <td className="text-center">
                      {overall.opportunitiesContactCount || 0}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.opportunitiesContactRate)}
                    </td>
                  </tr>
                  <tr className={TableStructure.odd}>
                    <td>
                      {overall.pipelineName
                        ? overall.pipelineName
                        : "Pipeline Revenue"}
                    </td>
                    <td className="text-center" colSpan={4}>
                      {formatPipeline(pipeline, currency)}
                    </td>
                  </tr>

                  {version === 2 && (
                    <Fragment>
                      <tr>
                        <td>No. of Won Deals</td>
                        <td className="text-center" colSpan={4}>
                          {overall.numberWonDeals}
                        </td>
                      </tr>
                      <tr className={TableStructure.odd}>
                        <td>Total Value of Deals</td>
                        <td className="text-center" colSpan={4}>
                          {formatPipeline(overall.valueofWonDeals, currency)}
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row className="d-md-none">
          <Col
            className={style({
              overflow: "auto",
            })}
          >
            <div>
              <table className={classes(TableStructure.table, "w-100")}>
                <thead>
                  <tr className={topHeader}>
                    <th></th>
                    <th className="text-center" colSpan={2}>
                      accounts
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className="text-center">count</th>
                    <th className="text-center">rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Sales Discussions</td>
                    <td className="text-center">{overall.callsAccountCount}</td>
                    <td className="text-center">
                      {formatRate(overall.callsAccountRate)}
                    </td>
                  </tr>
                  <tr className={TableStructure.odd}>
                    <td>Total Meetings Booked</td>
                    <td className="text-center">
                      {overall.meetingsAccountCount}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.meetingsAccountRate)}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Opportunities</td>
                    <td className="text-center">
                      {overall.opportunitiesAccountCount}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.opportunitiesAccountRate)}
                    </td>
                  </tr>
                  <tr className={TableStructure.odd}>
                    <td>Pipeline Revenue</td>
                    <td className="text-center" colSpan={2}>
                      {formatPipeline(overall.pipeline, currency)}
                    </td>
                  </tr>
                  {version === 2 && (
                    <Fragment>
                      <tr>
                        <td>No. of Won Deals</td>
                        <td className="text-center" colSpan={2}>
                          {overall.numberWonDeals}
                        </td>
                      </tr>
                      <tr className={TableStructure.odd}>
                        <td>Total Value of Deals</td>
                        <td className="text-center" colSpan={2}>
                          {formatPipeline(overall.valueofWonDeals, currency)}
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row className="d-md-none mt-3">
          <Col
            className={style({
              overflow: "auto",
            })}
          >
            <div>
              <table className={classes(TableStructure.table, "w-100")}>
                <thead>
                  <tr className={topHeader}>
                    <th></th>
                    <th className="text-center" colSpan={2}>
                      contacts
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className="text-center">count</th>
                    <th className="text-center">rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Engaged Calls</td>

                    <td className="text-center">{overall.callsContactCount}</td>
                    <td className="text-center">
                      {formatRate(overall.callsContactRate)}
                    </td>
                  </tr>
                  <tr className={TableStructure.odd}>
                    <td>Total Meetings Booked</td>

                    <td className="text-center">
                      {overall.meetingsContactCount}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.meetingsContactRate)}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Opportunities</td>

                    <td className="text-center">
                      {overall.opportunitiesContactCount}
                    </td>
                    <td className="text-center">
                      {formatRate(overall.opportunitiesContactRate)}
                    </td>
                  </tr>
                  <tr className={TableStructure.odd}>
                    <td>Pipeline Revenue</td>
                    <td className="text-center" colSpan={2}>
                      {formatPipeline(overall.pipeline, currency)}
                    </td>
                  </tr>

                  {version === 2 && (
                    <Fragment>
                      <tr>
                        <td>No. of Won Deals</td>
                        <td className="text-center" colSpan={2}>
                          {overall.numberWonDeals}
                        </td>
                      </tr>
                      <tr className={TableStructure.odd}>
                        <td>Total Value of Deals</td>
                        <td className="text-center" colSpan={2}>
                          {formatPipeline(overall.valueofWonDeals, currency)}
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Dashboard;
