import React, { FC, Fragment, ReactNode } from "react";
import { classes, style } from "typestyle";
import { Colour } from "../theme";
import { Row, Col } from "reactstrap";

const container = style({
  height: 300,
  display: "flex",
  flexDirection: "column",
});

const title = style({
  whiteSpace: "nowrap",
});

const subtitle = style({
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  textAlign: "center",
});

const bars = style({
  flexGrow: 1,
});
const barContainer = style({
  $nest: {
    "&.right": {
      marginLeft: "8%",
    },
  },
});

const figure = style({
  position: "absolute",
  fontSize: 12,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
const bar = style({
  position: "absolute",
  bottom: 0,
  // left: '15%',
  // right: '15%'
});
const barH = style({
  left: 0,
  top: "15%",
  bottom: "15%",
});
type Props = {
  title: string;
  subtitle?: string;
  contactsEngagedRate: number;
  accountsEngagedRate: number;
  altTitle?: string;
};
const Touchpoint: FC<Props> = (props: Props) => {
  let isDash =
    typeof props.contactsEngagedRate === "undefined" &&
    typeof props.accountsEngagedRate === "undefined";
  let contacts = Math.min(
    Math.round((props.contactsEngagedRate || 0) * 100),
    100
  );
  let accounts = Math.min(
    Math.round((props.accountsEngagedRate || 0) * 100),
    100
  );
  let contactsPerc = `${contacts}%`;
  let accountsPerc = `${accounts}%`;

  let accountsPos = style(
    accounts < 91
      ? {
          left: accountsPerc,
        }
      : {
          right: 100 - accounts + "%",
        }
  );

  let contactsPos = style(
    contacts < 91
      ? {
          left: contactsPerc,
        }
      : {
          right: 100 - contacts + "%",
          color: Colour.lightblue,
        }
  );
  let accountsPosV = style(
    accounts < 91
      ? {
          bottom: accountsPerc,
        }
      : {
          top: 100 - accounts + "%",
        }
  );

  let contactsPosV = style(
    contacts < 91
      ? {
          bottom: contactsPerc,
        }
      : {
          top: 100 - contacts + "%",
          color: Colour.lightblue,
        }
  );
  return (
    <Fragment>
      <div className={classes("d-md-none")}>
        <Row>
          <Col
            xs={5}
            className={classes("d-flex flex-column justify-content-center")}
          >
            <h5 className="text-capitalize " style={{ whiteSpace: "nowrap" }}>
              {props.title}
            </h5>
            <h6>{props.subtitle}</h6>
          </Col>
          <Col xs={7}>
            <div
              className={classes(
                "w-100",
                style({
                  height: 100,
                })
              )}
            >
              <div className={classes("w-100 h-50 position-relative top")}>
                <div
                  className={classes(
                    barH,
                    style({
                      backgroundColor: Colour.lightblue,
                      width: accountsPerc,
                    }),
                    "position-absolute"
                  )}
                ></div>
                <div className={classes(figure, "h-100", accountsPos)}>
                  <span className="px-1">{isDash ? "-" : accountsPerc}</span>
                </div>
              </div>
              <div className={classes("w-100 h-50 position-relative top")}>
                <div
                  className={classes(
                    barH,
                    style({
                      backgroundColor: Colour.white,
                      width: contactsPerc,
                    }),
                    "position-absolute"
                  )}
                ></div>
                <div className={classes(figure, "h-100", contactsPos)}>
                  <span className="px-1">{isDash ? "-" : contactsPerc}</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={classes(container, "d-none d-md-flex")}>
        <div className={classes(title, "text-uppercase text-center")}>
          <span className="d-inline d-sm-none d-xl-inline">{props.title}</span>
          <span className="d-none d-sm-inline d-xl-none">{props.altTitle}</span>
        </div>
        <div className={classes("m1-2 mb-2", subtitle)}>
          {newLineOMatic(props.subtitle) || <>&nbsp;</>}
        </div>
        <div className={classes(bars, "w-100 position-relative")}>
          <div
            className={
              "w-100 h-100 d-flex position-absolute justify-content-center"
            }
          >
            <div
              className={classes(
                "left h-100 d-inline-block position-relative bar-width-percent",
                barContainer
              )}
            >
              <div
                className={classes(
                  bar,
                  "w-100",
                  style({
                    backgroundColor: Colour.lightblue,
                    height: accountsPerc,
                  })
                )}
              ></div>
              <div className={classes(figure, "w-100", accountsPosV)}>
                <span>{isDash ? "-" : accountsPerc}</span>
              </div>
            </div>
            <div
              className={classes(
                " h-100 d-inline-block position-relative right bar-width-percent",
                barContainer
              )}
            >
              <div
                className={classes(
                  bar,
                  "w-100",
                  style({
                    backgroundColor: Colour.white,
                    height: contactsPerc,
                  })
                )}
              ></div>
              <div className={classes(figure, "w-100", contactsPosV)}>
                <span>{isDash ? "-" : contactsPerc}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Touchpoint;

const magicSplitter = "##";
const newLineOMatic = (line: string | undefined): ReactNode => {
  if (!line) {
    return line;
  }
  if (line.includes(magicSplitter)) {
    const [top, bottom] = line.split(magicSplitter);
    return (
      <>
        {top}
        <br />
        {bottom}
      </>
    );
  } else {
    return line;
  }
};
