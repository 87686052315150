import React, { FC, Fragment } from "react";
import { classes, style } from "typestyle";
import { Colour, panel, panelBorderRadius } from "../theme";

const icon = style({
  width: "40%",
  // height:'100%'
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopLeftRadius: panelBorderRadius,
  borderBottomLeftRadius: panelBorderRadius,
});

const figures = style({
  flexGrow: 1,
});

const text = style({
  fontSize: 8,
});
const number = style({
  fontSize: 25,
  fontWeight: 600,
});

type Props = {
  icon: any;
  name: any;
  quantity: any;
  color?: string;
};
const Callout: FC<Props> = (props: Props) => {
  let backgroundColor = props.color ? props.color : Colour.lightblue;
  return (
    <Fragment>
      <div className={classes(panel, "d-flex p-0")}>
        <div
          className={classes(
            icon,
            "d-flex",
            style({
              backgroundColor,
            })
          )}
        >
          <img src={props.icon} alt={props.name} />
        </div>
        <div className={classes("text-center", figures)}>
          <div className={number}>{props.quantity}</div>
          <div className={classes(text, "pb-2 text-uppercase")}>
            {props.name}
            {props.quantity === 1 ? "" : "s"}
          </div>
          {/* <div >
                       
                    </div>
                    <div>
                        
                    </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Callout;
