import React, { FC, Fragment, useEffect, useState } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { classes, style } from "typestyle";
import Dropper from "../components/Dropper";
import Stats from "../components/Stats";
import tick from "../img/tick.svg";
import { Colour, panel, TableStructure } from "../theme";
import { IAccount, IPersona } from "../type";
import { formatOpportunity } from "../utils";

const tableContainer = classes(
  TableStructure.tableContainer,
  style({
    overflow: "auto",
    width: "100%",
  })
);
const topHeader = style({
  backgroundColor: Colour.orange,
});
const limitWidth = style({
  maxWidth: 200,
  // wordWrap:'break-word',
  // textOverflow:'ellipsis',
  // overflow:'hidden',
});

const tableLocal = style({
  $nest: {
    "th,td": {
      textTransform: "none",
    },
    // td:{
    //     padding:  Structure.tablePadding
    // }
  },
});

type Props = {
  accounts: IAccount[];
  remainingAccounts: IAccount[];
  setSelectedValue: any;
  selectedValue: string | null;
  setSelectedFilter: any;
  selectedFilterName: string | null;
  filters: any[];
  personas: IPersona[];
  currency: string;
  version: number;
};

const Personas: FC<Props> = ({
  remainingAccounts,
  accounts,
  selectedValue,
  setSelectedValue,
  selectedFilterName,
  currency,
  setSelectedFilter,
  personas,
  filters,
  version,
}: Props) => {
  const [contactsCount, setcontactsCount] = useState(0);
  const [callsCount, setcallsCount] = useState(0);

  const [meetingsCount, setmeetingsCount] = useState(0);

  const [opportunityValue, setopportunityValue] = useState(0);
  useEffect(() => {
    if (selectedFilterName) {
      setcontactsCount(
        accounts.reduce((count, account) => {
          count += account.contactsCount;
          return count;
        }, 0)
      );
      setcallsCount(
        accounts.reduce((count, account) => {
          count += account.callsCount;
          return count;
        }, 0)
      );
      setmeetingsCount(
        accounts.reduce((count, account) => {
          count += account.meetingsCount;
          return count;
        }, 0)
      );
      setopportunityValue(
        accounts.reduce((count, account) => {
          count += account.pipelineRevenue;
          return count;
        }, 0)
      );
    } else if (selectedValue) {
      let account =
        accounts.find((account) => account.key === selectedValue) ||
        ({} as any);
      // setaccount(account);
      setcontactsCount(account.contactsCount || 0);
      setcallsCount(account.callsCount || 0);
      setmeetingsCount(account.meetingsCount || 0);
      setopportunityValue(account.pipelineRevenue);
    }
  }, [accounts, selectedValue, selectedFilterName]);

  return (
    <Fragment>
      <Row className="d-none d-md-block mt-5 mb-4">
        <Col>
          <h1> contact reporting</h1>
        </Col>
      </Row>
      <Row className={"mt-3 mb-2"}>
        <Col>
          <Dropper
            selectedValue={selectedValue}
            selectedFilterName={selectedFilterName}
            filters={filters}
            onValue={(value) => {
              setSelectedValue(value);
            }}
            onFilter={setSelectedFilter}
            all="CONTACTS"
            list={accounts}
            sublist={remainingAccounts}
            colour={Colour.orange}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={10}>
          <Stats
            colour={Colour.orange}
            contacts={contactsCount}
            calls={callsCount}
            meetings={meetingsCount}
            opportunity={formatOpportunity(opportunityValue, currency)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={classes(panel, tableContainer)}>
            {/* <Row className="mt-1 mb-2">
              <Col>
                <h3>&nbsp;</h3>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <table
                  className={classes(TableStructure.table, "w-100", tableLocal)}
                >
                  <thead className={topHeader}>
                    <tr>
                      <th>Name</th>

                      <th>Account</th>
                      <th>Position</th>
                      {[3, 4].includes(version) && <th>Channel</th>}
                      <th>TP1</th>
                      <th>TP2</th>
                      <th>TP3</th>
                      <th>TP4</th>

                      {[4, 3].includes(version) ? (
                        <>
                          {/* <th>TP7</th> */}

                          {version === 3 && <th>ETP</th>}
                        </>
                      ) : (
                        <>
                          <th>TP5</th>
                          {![6, 5].includes(version) && <th>TP6</th>}

                          {version === 2 && <th>TP7</th>}
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {personas
                      .filter(
                        (persona) =>
                          persona.accountKey === selectedValue ||
                          (selectedFilterName &&
                            accounts.find(
                              (account) => account.key === persona.accountKey
                            ))
                      )
                      .map((persona, i) => (
                        <tr
                          key={i}
                          className={classes(i % 2 === 1 && TableStructure.odd)}
                        >
                          <td>
                            {persona.firstName}&nbsp;
                            {persona.lastName}
                          </td>
                          <td>
                            <div
                              className={classes(limitWidth, "text-truncate")}
                            >
                              {persona.account}
                            </div>
                          </td>
                          <td>
                            <div
                              className={classes(limitWidth, "text-truncate")}
                            >
                              {persona.title}
                            </div>
                          </td>
                          {[3, 4].includes(version) && (
                            <td>{persona.channel}</td>
                          )}
                          <td>{ticker(persona.touchpoint1)}</td>
                          <td>{ticker(persona.touchpoint2)}</td>
                          <td>
                            {ticker(
                              persona.touchpoint3,
                              `tp3-${i}`,
                              persona.touchpoint3Notes
                            )}
                          </td>
                          <td>{ticker(persona.touchpoint4)}</td>

                          {[3, 4].includes(version) ? (
                            <>
                              {version === 3 && (
                                <td>{ticker(persona.extraTouchpoint)}</td>
                              )}
                            </>
                          ) : (
                            <>
                              <td>{ticker(persona.touchpoint5)}</td>
                              {![6, 5].includes(version) && (
                                <td>{ticker(persona.touchpoint6)}</td>
                              )}
                              {version === 2 && (
                                <td>{ticker(persona.touchpoint7)}</td>
                              )}
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

const ticker = (val: string, id?, comment?) => {
  let Icon: any = () => null;
  switch (true) {
    // case !!comment:
    case val?.trim && val.trim() === "y":
    case val?.trim && val.trim() === "Y":
      Icon = () => (
        <span className="d-flexf " id={id}>
          <img src={tick} alt="T" />
          {!!comment && "*"}
        </span>
      );
      break;
    case typeof val === "string":
      Icon = () => (
        <span className="d-flexf " id={id}>
          {val}
          {!!comment && "*"}
        </span>
      );
      break;
  }
  return (
    <>
      {Icon()}
      {!!Icon() && comment && (
        <UncontrolledTooltip placement="top" target={id}>
          {comment}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default Personas;
