import React, { FC, Fragment } from "react";
import { classes, style } from "typestyle";
import { Colour, tableBorder, panel } from "../theme";
import { Row, Col } from "reactstrap";
import Touchpoint from "./Touchpoint";
import { IOverall, DEFAULT_TOUCHPOINT } from "../type";

const container = style({});

const touchpointRow = style({});
const line = style({
  borderTop: tableBorder,
});

const getFirstFourWidth = (version) => {
  switch (version) {
    case 2:
      return 17;
    case 4:
    case 3:
      return 3;
    case 5:
    case 6:
      return 20;
    default:
      return 2;
  }
};

type Props = {
  overall: IOverall;
  touchpoint: typeof DEFAULT_TOUCHPOINT;
  version: number;
};
const Engagement: FC<Props> = ({ overall, touchpoint, version }: Props) => {
  console.log("overall, touchpoint, version", { overall, touchpoint, version });

  return (
    <Fragment>
      <div className={classes(panel, container)}>
        <Row className="mt-1 mb-2">
          <Col>
            <h3>TOUCHPOINT ENGAGEMENT ANALYSIS</h3>
          </Col>
        </Row>
        <Row>
          <Col className="px-5">
            <Row className={classes(touchpointRow, "mt-3")}>
              <Col
                xs={12}
                className={`px-1 col-md-${getFirstFourWidth(version)}`}
              >
                <Touchpoint
                  title="touchpoint 1"
                  altTitle="tp1"
                  subtitle={touchpoint.long.touchpoint1}
                  accountsEngagedRate={overall.touchpoint1AccountRate}
                  contactsEngagedRate={overall.touchpoint1ContactRate}
                />
              </Col>
              <Col
                xs={12}
                className={`px-1 col-md-${getFirstFourWidth(version)}`}
              >
                <Touchpoint
                  title="touchpoint 2"
                  altTitle="tp2"
                  subtitle={touchpoint.long.touchpoint2}
                  accountsEngagedRate={overall.touchpoint2AccountRate}
                  contactsEngagedRate={overall.touchpoint2ContactRate}
                />
              </Col>
              <Col
                xs={12}
                className={`px-1 col-md-${getFirstFourWidth(version)}`}
              >
                <Touchpoint
                  title="touchpoint 3"
                  altTitle="tp3"
                  subtitle={touchpoint.long.touchpoint3}
                  accountsEngagedRate={overall.touchpoint3AccountRate}
                  contactsEngagedRate={overall.touchpoint3ContactRate}
                />
              </Col>
              <Col
                xs={12}
                className={`px-1 col-md-${getFirstFourWidth(version)}`}
              >
                <Touchpoint
                  title={`touchpoint 4${[3].includes(version) ? "&5" : ""}`}
                  altTitle={`tp4${[3].includes(version) ? "&5" : ""}`}
                  subtitle={touchpoint.long.touchpoint4}
                  accountsEngagedRate={overall.touchpoint4AccountRate}
                  contactsEngagedRate={overall.touchpoint4ContactRate}
                />
              </Col>

              {![3, 4].includes(version) && (
                <>
                  <Col
                    xs={12}
                    className={`px-1 col-md-${version === 2 ? 17 : 2}`}
                  >
                    <Touchpoint
                      title="touchpoint 5"
                      altTitle="tp5"
                      subtitle={touchpoint.long.touchpoint5}
                      accountsEngagedRate={overall.touchpoint5AccountRate}
                      contactsEngagedRate={overall.touchpoint5ContactRate}
                    />
                  </Col>
                  {![6, 5].includes(version) && (
                    <Col
                      xs={12}
                      className={`px-1 col-md-${version === 2 ? 17 : 2}`}
                    >
                      <Touchpoint
                        title="touchpoint 6"
                        altTitle="tp6"
                        subtitle={touchpoint.long.touchpoint6}
                        accountsEngagedRate={overall.touchpoint6AccountRate}
                        contactsEngagedRate={overall.touchpoint6ContactRate}
                      />
                    </Col>
                  )}
                </>
              )}
              {version === 2 && (
                <Col xs={12} className={`px-1 col-md-17`}>
                  <Touchpoint
                    title="touchpoint 7"
                    altTitle="tp7"
                    subtitle={touchpoint.long.touchpoint7}
                    accountsEngagedRate={overall.touchpoint7AccountRate}
                    contactsEngagedRate={overall.touchpoint7ContactRate}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <hr className={classes(line, "mt-4 mb-3")} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <div className="mr-3">
                <div
                  className={classes(
                    "square d-inline-block",
                    style({
                      width: 12,
                    })
                  )}
                >
                  <div
                    className={classes(
                      "content",
                      style({
                        backgroundColor: Colour.lightblue,
                      })
                    )}
                  ></div>
                </div>
                <div className=" d-inline-block ml-2">Engaged accounts</div>
              </div>
              <div className="ml-3">
                <div
                  className={classes(
                    "square d-inline-block",
                    style({
                      width: 12,
                    })
                  )}
                >
                  <div
                    className={classes(
                      "content",
                      style({
                        backgroundColor: Colour.white,
                      })
                    )}
                  ></div>
                </div>
                <div className=" d-inline-block ml-2">Engaged contacts</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Engagement;
