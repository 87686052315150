import { SignIn } from 'aws-amplify-react';
import React from 'react';
import { Button, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import { classes, style } from 'typestyle';
import { bgGreen, Colour } from '../theme';


const container = style({
  height: '100vh',
  background: `linear-gradient(${Colour.blue},${Colour.darkblue})`
})
const row = style({
})
const col = style({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
const inputStyle = style({
  borderRadius: 0,
  // backgroundColor: `${Colour.green} !important`,
  // color: Colour.white,
  fontWeight: 700
})


const boxStyle = style({

})
class CustomSignIn extends SignIn {
  urlUser: any = null
  hasUrlUser: boolean = false
  constructor(props: any) {
    super(props)
    this._validAuthStates = ["signIn", "signedOut", "signedUp"]
    let host = window.location.host
    if (host.indexOf('radishcampaign.com') > 0) {
      this.hasUrlUser = true
      setTimeout(() => {
        this.setState({
          username: host.split('.')[0]
        })
        this.urlUser = host.split('.')[0]
      }, 1);

    }
    // console.log('urlUser', this.urlUser)
  }
  componentDidUpdate(props: any) {
    // console.log('custom sign in udate', props)
  }
  render() {
    return (
      <Container fluid className={container}>
        <Row className={classes(row, 'h-100')}>
          <Col xs={12} className={col}>
            <div className={boxStyle}>
              <Row className={'mb-4'}>
                <Col>
                  <h3>Digital Radish ABM Campaign</h3>
                </Col>
              </Row>

              {this.hasUrlUser ? <Input
                type="hidden"
                name="username"
                key="username"
                onChange={this.handleInputChange}
                id="username"
                // value={this.urlUser}
              /> : <FormGroup row>
                  <Col xs={12}>
                    <Input
                      className={classes(inputStyle)}
                      placeholder='Your Campaign Id'
                      type="text"
                      name="username"
                      key="username"
                      onChange={this.handleInputChange}
                      id="username"
                    />


                  </Col>
                </FormGroup>}
              <FormGroup row>
                <Col xs={12} >

                  <Input
                    className={classes(inputStyle)}
                    placeholder='Your password'
                    type="password"
                    name="password"
                    key="password"
                    onChange={this.handleInputChange}
                    id="password"
                  />

                </Col>
              </FormGroup>
              <FormGroup row className={'mt-4'}>

                <Col xs={12} >
                  <Button type="button"
                    className={classes(inputStyle, bgGreen)}
                    onClick={(e) => super.signIn(e)}>Login</Button>
                </Col>
              </FormGroup>
            </div>


          </Col>
        </Row>
      </Container>
    )
  }
}

export default CustomSignIn;
