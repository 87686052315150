import React, { FC, Fragment } from "react";
import { Col, Row } from "reactstrap";
import Circular from "../components/Circular";
import { IAccount } from "../type";

type Props = {
  account: IAccount;
  version: number;
};

const Pies: FC<Props> = ({ account, version }: Props) => {
  if (!account) {
    return null;
  }

  const pies = [
    [
      {
        rate: account.touchpoint1Rate || 0,
        count: account.touchpoint1Count || 0,
        name: "touchpoint 1",
      },
      {
        rate: account.touchpoint2Rate || 0,
        count: account.touchpoint2Count || 0,
        name: "touchpoint 2",
      },
      {
        rate: account.touchpoint3Rate || 0,
        count: account.touchpoint3Count || 0,
        name: "touchpoint 3",
      },
      {
        rate: account.touchpoint4Rate || 0,
        count: account.touchpoint4Count || 0,
        name: "touchpoint 4",
      },
    ],
  ];

  if ([ 6].includes(version)) {
    pies[0].push({
      rate: account.touchpoint5Rate || 0,
      count: account.touchpoint5Count || 0,
      name: "touchpoint 5",
    });
  }

  if (version === 2) {
    pies[0].push(
      {
        rate: account.touchpoint5Rate || 0,
        count: account.touchpoint5Count || 0,
        name: "touchpoint 5",
      },
      {
        rate: account.touchpoint6Rate || 0,
        count: account.touchpoint6Count || 0,
        name: "touchpoint 6",
      }
    );
    if (
      typeof account.touchpoint7Rate !== "undefined" &&
      typeof account.touchpoint7Count !== "undefined"
    ) {
      pies[0].push({
        rate: account.touchpoint7Rate || 0,
        count: account.touchpoint7Count || 0,
        name: "touchpoint 7",
      });
    }
  }
  if (version === 3) {
    pies[0][3].name = "touchpoint 4&5";
  }
  return (
    <Fragment>
      <Row>
        <Col>
          {pies.map((row, i) => (
            <Row key={i}>
              {row.map((col, k) => (
                <Col key={k} xs={12} sm={6} md={6} lg={6} xl={4}>
                  <Circular {...col} />
                </Col>
              ))}
            </Row>
          ))}
        </Col>
      </Row>
    </Fragment>
  );
};

export default Pies;
