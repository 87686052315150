import { Auth } from 'aws-amplify';
import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Col, Collapse, Nav, Navbar, NavItem, Row } from 'reactstrap';
import { classes, style } from 'typestyle';
import logo from '../img/logo.svg';
import { Colour, tableBorder } from '../theme';
import { routes } from '../utils';
import './Menu.scss';

const hr = style({
    border: tableBorder,
    width: '100%'
})

const listGroupItem = style({
    background: 'transparent',
    border: 'none',
    borderRadius: '0 !important',
    borderRight: `3px transparent solid`,
    fontSize: 22,
    fontWeight: 500,
    $nest: {
        '&.active': {
            borderRight: `3px ${Colour.lightblue} solid`
        }
    }
})


const bar = style({
    backgroundColor: Colour.white,
    height: '10%',
    width: '100%',
    position: 'absolute',
    transition: 'all 0.4s ease',
    left: '0%'
})

const top = style({
    top: '10%',
    transformOrigin: 'center',
    $nest: {
        '.open &': {
            transform: 'rotate(45deg)',
            top: '45%'
        }
    }
})

const mid = style({
    top: '45%',
    $nest: {
        '.open &': {
            width: '0%',
            left: '50%'
        }
    }
})
const bot = style({
    bottom: '10%',
    transformOrigin: 'center',
    $nest: {
        '.open &': {
            transform: 'rotate(-45deg)',
            bottom: '45%'
        }
    }
})
const navbar = style({
    backgroundColor: Colour.blue
})

const toggler = style({

})
type Props = RouteComponentProps & {
    logoUrl: string
    username: string
}
const NavTop: FC<Props> = (props: Props) => {

    const [collapsed, setCollapsed] = useState(true);
    
   
    const toggleNavbar = () => setCollapsed(!collapsed);


    const isActive = (item: any) => props.history.location.pathname === item.url

    return (
        <Navbar className={classes(navbar, 'sticky-top d-md-none')}>
            <Row className={style({width:'100vw'})}>
                <Col className='d-flex justify-content-center align-items-center'>
                    <img src={logo} className={classes('w-100')} alt='Digital Radish' />
                </Col>
                <Col className='d-flex justify-content-center align-items-center'>
                    <img src={props.logoUrl} className={classes("w-100")} alt={props.username} />
                </Col>
                <Col>
                    <div
                        className={classes('square float-right')}
                        onClick={toggleNavbar}>
                        <div className={classes('content', toggler, !collapsed && 'open')}>
                            <div className={classes(bar, top)}></div>
                            <div className={classes(bar, mid)}></div>
                            <div className={classes(bar, bot)}></div>
                        </div>
                    </div>

                </Col>
            </Row>


            <Collapse isOpen={!collapsed} navbar>
                <Nav navbar>
                    {routes.map(route => <NavItem
                        key={route.url}
                        className={classes(listGroupItem, isActive(route) && 'active', ' mb-3 mt-3')}
                        onClick={() => {
                            props.history.push(route.url)
                            setCollapsed(true)
                        }}>
                        {route.text}
                    </NavItem>)}
                    <hr className={hr} />
                    <NavItem

                        className={classes(listGroupItem)}
                        onClick={() => {
                            Auth.signOut()
                            setCollapsed(true)
                        }}>
                        Log out
                    </NavItem>
                    <hr className={hr} />
                </Nav>
            </Collapse>
        </Navbar>

    );
}

export default withRouter(NavTop);
