import React, { FC, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { panel, Colour, TableStructure } from "../theme";
import { style, classes } from "typestyle";
const topHeader = style({
  backgroundColor: Colour.lightblue,
});

const tableLocal = style({
  tableLayout: "fixed",
  $nest: {
    "th,td": {
      textTransform: "none",
    },
    // td:{
    //     padding:  Structure.tablePadding
    // }
  },
});
const limitWidth = style({
  // maxWidth: 120,
});
type Props = {
  hotAccounts: any[];
  nutureAccounts: any[];
  hotPersonas: any[];
  version: number;
};
const Lists: FC<Props> = ({
  hotAccounts,
  hotPersonas,
  nutureAccounts,
  version,
}: Props) => {
  return (
    <Fragment>
      <Row>
        <Col md={12} lg={4}>
          <div className={panel}>
            <Row className="mt-1 mb-2">
              <Col>
                <h3>hot accounts</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <table
                  className={classes(TableStructure.table, "w-100", tableLocal)}
                >
                  <thead>
                    <tr className={topHeader}>
                      <th></th>
                      <th>
                        <div className={classes(limitWidth, "text-truncate")}>
                          Touchpoint reached
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotAccounts.map((hotAccount, i) => (
                      <tr
                        key={hotAccount.key}
                        className={classes(i % 2 === 1 && TableStructure.odd)}
                      >
                        <td>{hotAccount.name}</td>
                        <td>{hotAccount.touchpointReached}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={12} lg={4}>
          <div className={panel}>
            <Row className="mt-1 mb-2">
              <Col>
                <h3>hot contacts</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <table
                  className={classes(TableStructure.table, "w-100", tableLocal)}
                >
                  <thead>
                    <tr className={topHeader}>
                      <th></th>
                      {version === 3 ? (
                        <th>Account</th>
                      ) : (
                        <>
                          <th>Title</th>
                          <th style={{ width: 50 }}>TP</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {hotPersonas.map((hotPersona, i) => (
                      <tr
                        key={hotPersona.key}
                        className={classes(i % 2 === 1 && TableStructure.odd)}
                      >
                        <td className={classes("text-truncate")}>
                          {hotPersona.name}
                        </td>

                        {version === 3 ? (
                          <td>
                            <div
                              className={classes(limitWidth, "text-truncate")}
                            >
                              {hotPersona.account}
                            </div>
                          </td>
                        ) : (
                          <>
                            <td>
                              <div
                                className={classes(limitWidth, "text-truncate")}
                              >
                                {hotPersona.title}
                              </div>
                            </td>
                            <td style={{ width: 50 }}>
                              {/* {version === 2
                                ? Math.round(
                                    (hotPersona.touchpointReached / 7) * 100
                                  ) + "%"
                                : hotPersona.touchpointReached} */}
                              {hotPersona.touchpointReached}
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={12} lg={4}>
          <div className={panel}>
            <Row className="mt-1 mb-2">
              <Col>
                <h3>accounts to nurture</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <table
                  className={classes(TableStructure.table, "w-100", tableLocal)}
                >
                  <thead>
                    <tr className={topHeader}>
                      <th></th>
                      <th>
                        <div className={classes(limitWidth, "text-truncate")}>
                          Touchpoint reached
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {nutureAccounts.map((nutureAccount, i) => (
                      <tr
                        key={nutureAccount.key}
                        className={classes(i % 2 === 1 && TableStructure.odd)}
                      >
                        <td>
                          <div className={classes(limitWidth, "text-truncate")}>
                            {nutureAccount.name}
                          </div>
                        </td>
                        <td>{nutureAccount.touchpointReached}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Lists;
