import { FILTER_NAME_ALL } from "./type";

export const filtersBase = [
  {
    name: FILTER_NAME_ALL,
    key: "0",
  },
];

export const filtersV2: any[] = [
  {
    name: FILTER_NAME_ALL,
    key: "0",
    overallSplit: [
      "data1FewOthers",
      "data1FewFinance",
      "data1FewRetail",
      "data11",
    ],
  },
  {
    name: "1-1",
    key: "1",
    overallSplit: ["data11"],
  },
  {
    name: "1-Few",
    key: "2",
    overallSplit: ["data1FewFinance", "data1FewFinance", "data1FewRetail"],
  },
  {
    name: "1-Few Retail",
    key: "3",
    overallSplit: ["data1FewRetail"],
  },
  {
    name: "1-Few Finance",
    key: "4",
    overallSplit: ["data1FewFinance"],
  },
  {
    name: "1-Few Others",
    key: "5",
    overallSplit: ["data1FewOthers"],
  },
];

export const filtersV4 = [
  {
    name: FILTER_NAME_ALL,
    key: "0",
    overallSplit: ["dataUS", "dataAUS", "dataEMEA"],
  },
  {
    name: "US",
    key: "1",
    overallSplit: ["dataUS"],
  },
  {
    name: "AUS",
    key: "2",
    overallSplit: ["dataAUS"],
  },
  {
    name: "EMEA",
    key: "3",
    overallSplit: ["dataEMEA"],
  },
];

export const filtersV5 = [
  {
    name: FILTER_NAME_ALL,
    key: "0",
    overallSplit: ["dataUS", "dataAPJ"],
  },
  {
    name: "US",
    key: "1",
    overallSplit: ["dataUS"],
  },
  {
    name: "APJ",
    key: "2",
    overallSplit: ["dataAPJ"],
  },
];
