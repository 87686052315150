import { Colour } from "./theme";

export const formatPipeline = (value, currency = "£") => {
  value = Number(value) || 0;
  return `${currency}${abbreviateNumber(value)}`;
};
export const formatRate = (value) => {
  value = value * 100;
  value = Math.round(value);
  return `${value || 0}%`;
};

export const formatOpportunity = (value: any, currency = "£"): string => {
  value = Number(value) || 0;
  return `${currency}${abbreviateNumber(value, 0)}`;
  // value = value / 1000;
  // value = String(value)
  // value = value.substr(0, 3).trim('.')
  // return '£' + value + 'K'
};

export const routes = [
  {
    text: "Overall Campaign Reporting",
    url: "/",
    active: true,
    background: Colour.lightblue,
  },
  {
    text: "Account Reporting",
    url: "/account",
    active: false,
    background: Colour.green,
  },
  {
    text: "Contact Reporting",
    url: "/contact",
    active: false,
    background: Colour.orange,
  },
];

export const abbreviateNumber = (number, length = 0) => {
  number = Number(number) || 0;
  // what tier? (determines SI symbol)
  const tier = (Math.log10(number) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  const symbol = [
    ["", "K", "M"],
    ["", "K", "MILL"],
    ["", "THOUSAND", "MILLION"],
  ];

  const suffix = symbol[length][tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;
  let numStr = scaled.toFixed(1);

  // trim last .0 if already have 3 characters
  if (numStr.length === 5) {
    numStr = numStr.substr(0, 3);
  }
  // format number and add suffix
  return numStr + (length > 0 ? " " : "") + suffix;
};
