import React, { FC } from "react";
import { Col, Row } from "reactstrap";
import calls from "../img/calls.svg";
import contacts from "../img/contacts.svg";
import meetings from "../img/meetings.svg";
import opportunity from "../img/opportunity.svg";
import Callout from "./Callout";
import accountsIcon from "../img/accounts.svg";

type Props = {
  contacts: number;
  accounts?: number;
  calls: number;
  meetings: number;
  opportunity: string;
  colour?: string;
};
const Stats: FC<Props> = (props: Props) => {
  let widgetWidth = typeof props.accounts === "undefined" ? 6 : 4;
  let rowWidth = typeof props.accounts === "undefined" ? [6, 6] : [7, 5];
  return (
    <Row>
      <Col xs={12} xl={rowWidth[0]}>
        <Row>
          {typeof props.accounts === "undefined" ? null : (
            <Col xs={widgetWidth}>
              <Callout
                color={props.colour}
                icon={accountsIcon}
                quantity={props.accounts}
                name="account"
              />
            </Col>
          )}
          <Col xs={widgetWidth}>
            <Callout
              color={props.colour}
              icon={contacts}
              quantity={props.contacts}
              name={"contact"}
            />
          </Col>
          <Col xs={widgetWidth}>
            <Callout
              color={props.colour}
              icon={calls}
              quantity={props.calls}
              name={"call"}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12} xl={rowWidth[1]}>
        <Row>
          <Col xs={6}>
            <Callout
              color={props.colour}
              icon={meetings}
              quantity={props.meetings}
              name={"meeting"}
            />
          </Col>
          <Col xs={6}>
            <Callout
              color={props.colour}
              icon={opportunity}
              quantity={props.opportunity}
              name={"opportunities"}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Stats;
